import React from 'react'
import { Link } from 'gatsby'
import { Card, CardBody, CardFooter, CardTitle } from 'reactstrap'
import styled from 'styled-components'
import Img, { FluidObject } from 'gatsby-image'

type GroupProps = {
  color: string
  img: FluidObject
  initials: string
  name: string
  slug: string
}

export const GroupCard: React.FC<GroupProps> = props => (
  <StyledCard color={props.color}>
    <Link to={`/group/${props.slug}`}>
      <StyledImg
        alt={props.initials}
        className="card-img-top"
        fadeIn
        fluid={props.img}
        title={props.initials}
      />
      <CardBody>
        <StyledCardTitle>{props.name}</StyledCardTitle>
      </CardBody>
    </Link>
    <StyledCardFooter color={props.color}>
      <Link to={`/board/${props.slug}`}>Bacheca {props.initials}</Link>
    </StyledCardFooter>
  </StyledCard>
)

const StyledCard = styled(Card)`
  border-color: ${(props): string => props.color};
  height: 100%;
  overflow: hidden;
`

const StyledImg = styled(Img)`
  transition: transform 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
    transform: scale(1.15);
  }
`

const StyledCardTitle = styled(CardTitle)`
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
`

const StyledCardFooter = styled(CardFooter)`
  background-color: ${(props): string => props.color};
  font-style: italic;

  & > a {
    color: black;
    float: right;
  }
`
