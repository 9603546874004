import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Section } from '../../common'

interface EventsQuery {
  events: {
    nodes: [
      {
        title: string
        link: {
          link: string
        }
      }
    ]
  }
}

export const EventsSection: React.FC = () => {
  const data = useStaticQuery<EventsQuery>(graphql`
    query Events {
      events: allContentfulHomeEvents {
        nodes {
          link {
            link
          }
        }
      }
    }
  `).events.nodes[0]

  return (
    <Section id="events" title="Eventi" fluid="md">
      <StyledIframe frameBorder="0" src={data.link.link} title="calendario" />
    </Section>
  )
}

const StyledIframe = styled.iframe`
  height: 40rem;
  width: 100%;
`
