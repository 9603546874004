import React from 'react'
import { CardDeck } from 'reactstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { Section } from '../../common'
import { GroupCard } from './partial'

interface Group {
  color: string
  mainPic: {
    fluid: FluidObject
  }
  name: string
  slug: string
}

interface GroupsQuery {
  lc: Group
  eg: Group
  rs: Group
}

export const GroupSection: React.FC = () => {
  const data = useStaticQuery<GroupsQuery>(graphql`
    fragment GroupImgStuff on ContentfulGroup {
      color
      mainPic {
        fluid(
          maxHeight: 360
          maxWidth: 480
          quality: 100
          resizingBehavior: SCALE
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      name
      slug
    }

    query Groups {
      lc: contentfulGroup(initials: { eq: "L / C" }) {
        ...GroupImgStuff
      }
      eg: contentfulGroup(initials: { eq: "E / G" }) {
        ...GroupImgStuff
      }
      rs: contentfulGroup(initials: { eq: "R / S" }) {
        ...GroupImgStuff
      }
    }
  `)

  return (
    <Section id="groups" title="Le nostre branche">
      <CardDeck>
        <GroupCard {...data.lc} img={data.lc.mainPic.fluid} initials="L / C" />
        <GroupCard {...data.eg} img={data.eg.mainPic.fluid} initials="E / G" />
        <GroupCard {...data.rs} img={data.rs.mainPic.fluid} initials="R / S" />
      </CardDeck>
    </Section>
  )
}
