import React from 'react'
import { Row, Col } from 'reactstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { Document } from '@contentful/rich-text-types'
import { Section } from '../../common'
import { RichText } from '../../../common'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

interface EnrollmentQuery {
  enrollment: {
    nodes: [
      {
        content: {
          json: Document
        }
        title: string
        links: [
          {
            caption: string
            url: string
          }
        ]
      }
    ]
  }
}

export const EnrollmentSection: React.FC = () => {
  const data = useStaticQuery<EnrollmentQuery>(graphql`
    query Enrollment {
      enrollment: allContentfulHomeEnrollment(limit: 1) {
        nodes {
          content {
            json
          }
          title
          links {
            caption
            url
          }
        }
      }
    }
  `).enrollment.nodes[0]

  return (
    <Section id="enrollment" title={data.title}>
      <Row>
        <Col lg={9}>
          <RichText align="justify" content={data.content.json} />
        </Col>
        <StyledCol lg={3}>
          <Row>
            {data.links.map(link => (
              <Col key={link.url} xs={12} sm={6} md={6} lg={12}>
                <FormLink
                  href={link.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  role="button"
                >
                  {link.caption}
                </FormLink>
              </Col>
            ))}
          </Row>
        </StyledCol>
      </Row>
    </Section>
  )
}

const FormLink = styled(OutboundLink)`
  background: var(--roma24-blue);
  border-radius: 50px;
  color: white;
  display: block;
  font-weight: bold;
  margin: 0.4rem;
  padding: 0.4rem 1.2rem;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;

  &:hover {
    background: var(--roma24-red);
  }
`

const StyledCol = styled(Col)`
  text-align: center;
  vertical-align: middle;
`
