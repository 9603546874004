import React, { useCallback, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { useDispatch } from 'react-redux'
import {
  showEmailSentErrorMsg,
  showEmailSentMsg,
} from '../../../../state/actions'
import { Section } from '../../common'

interface ContactQuery {
  contact: {
    nodes: [
      {
        title: string
        email: string
        address: string
        mapLink: string
        iframeUrl: string
      }
    ]
  }
}

export const ContactSection: React.FC = () => {
  const data = useStaticQuery<ContactQuery>(graphql`
    query Contact {
      contact: allContentfulHomeContacts(limit: 1) {
        nodes {
          address
          title
          email
          mapLink
          iframeUrl
        }
      }
    }
  `)

  const contactInfo = data.contact.nodes[0]

  const dispatch = useDispatch()

  const contactFormId = 'contact-form-id'
  const [canSubmit, setSubmittable] = useState(true)

  const emailSentSuccessfully = useCallback(() => {
    dispatch(
      showEmailSentMsg({
        error: false,
        message: 'Grazie! Abbiamo ricevuto il tuo messaggio.',
      })
    )
    ;(document.getElementById(contactFormId) as HTMLFormElement).reset()
    setSubmittable(true)
  }, [dispatch, setSubmittable])

  const errorSendingEmail = useCallback(() => {
    dispatch(
      showEmailSentErrorMsg({
        error: false,
        message:
          'Abbiamo riscontrato un errore inviando il tuo messaggio. Riprova.',
      })
    )
    setSubmittable(true)
  }, [dispatch, setSubmittable])

  const handleSubmission = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      event.currentTarget.reportValidity()
      setSubmittable(false)
      await fetch('/', {
        body: new FormData(event.currentTarget),
        credentials: 'omit',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        mode: 'no-cors',
        referrerPolicy: 'no-referrer',
      })
        .then(response =>
          response.ok ? emailSentSuccessfully() : errorSendingEmail()
        )
        .catch(() => errorSendingEmail())
    },
    [emailSentSuccessfully, errorSendingEmail, setSubmittable]
  )

  return (
    <Section id="contact" title={contactInfo.title} fluid>
      <Row>
        <Col lg={6}>
          <MapIframe
            frameBorder="0"
            src={contactInfo.iframeUrl}
            title="mappa"
          />
        </Col>
        <Col lg={6}>
          <ContactInfo>
            <Col sm={6} title="indirizzo">
              <StyledA
                href={contactInfo.mapLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon icon="map-marker-alt" size="lg" />
                <address>{contactInfo.address}</address>
              </StyledA>
            </Col>
            <Col sm={6} id="contact-email" title="email">
              <StyledA
                href={`mailto:${contactInfo.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="envelope" size="lg" />
                <div>{contactInfo.email}</div>
              </StyledA>
            </Col>
          </ContactInfo>
          <Form
            id={contactFormId}
            onSubmit={handleSubmission}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="contact"
            role="form"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-row">
              <Col md={6}>
                <FormGroup>
                  <Label for="name-input" hidden>
                    Nome
                  </Label>
                  <Input
                    name="name"
                    id="name-input"
                    required
                    placeholder="Nome"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email-input" hidden>
                    Email
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    id="email-input"
                    required
                    placeholder="Email"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="subject-input" hidden>
                    Titolo
                  </Label>
                  <Input
                    name="subject"
                    id="subject-input"
                    placeholder="Titolo"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="message-input" hidden>
                    Messaggio
                  </Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="message-input"
                    rows={5}
                    required
                    placeholder="Messaggio"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Button block disabled={!canSubmit}>
                  INVIA
                </Button>
              </Col>
            </div>
          </Form>
        </Col>
      </Row>
    </Section>
  )
}

const StyledA = styled.a`
  color: black;
`

const MapIframe = styled.iframe`
  height: 100%;
  min-height: 20rem;
  width: 100%;

  @media only screen and (max-width: 992px) {
    padding-bottom: 1rem;
  }
`

const ContactInfo = styled(Row)`
  text-align: center;

  @media only screen and (max-width: 1024px) {
    padding: 1rem 0;
  }
`
