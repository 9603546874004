import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Document } from '@contentful/rich-text-types'
import { Section } from '../../common'
import { RichText } from '../../../common'

interface AboutQuery {
  about: {
    nodes: [
      {
        content: {
          json: Document
        }
        title: string
      }
    ]
  }
}

export const AboutSection: React.FC = () => {
  const data = useStaticQuery<AboutQuery>(graphql`
    query About {
      about: allContentfulHomeAbout(limit: 1) {
        nodes {
          title
          content {
            json
          }
        }
      }
    }
  `).about.nodes[0]

  return (
    <Section id="about" title={data.title}>
      <RichText align="justify" content={data.content.json} />
    </Section>
  )
}
