import React from 'react'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import { Header, HeaderProps } from '../../../common'

type SectionProps = {
  fluid?: boolean | string
  id: string
} & HeaderProps

export const Section: React.FC<SectionProps> = props => {
  return (
    <StyledSection id={props.id}>
      <Header title={props.title} subtitle={props.subtitle} />
      <Container fluid={props.fluid}>{props.children}</Container>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  box-shadow: inset 0 0 1rem 0 rgba(0, 0, 0, 0.4);
  padding: 6rem 2rem;

  @media only screen and (max-width: 1024px) {
    padding: 3rem 1rem;
  }

  &:nth-child(2n + 1) {
    background-color: rgba(106, 145, 229);
  }

  transition: 0.5s ease-in-out;
`
