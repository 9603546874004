import React from 'react'
import { Layout } from '../components/common'
import { MainContent } from '../components/MainContent'
import { ROUTES } from '../constants/routes'

const SinglePage: React.FC = () => (
  <Layout navigation={{ location: ROUTES.HOME }}>
    <MainContent />
  </Layout>
)

export default SinglePage
