import React, { useCallback, useState } from 'react'
import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from 'reactstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'

interface SliderQuery {
  slider: {
    nodes: [
      {
        photos: [
          {
            id: string
            title: string
            description: string
            fluid: FluidObject
          }
        ]
      }
    ]
  }
}

export const SliderSection: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const setAnimatingFalse = useCallback(() => setAnimating(false), [
    setAnimating,
  ])
  const setAnimatingTrue = useCallback(() => setAnimating(true), [setAnimating])

  const data = useStaticQuery<SliderQuery>(graphql`
    query Slider {
      slider: allContentfulHomePhotoSlider(limit: 1) {
        nodes {
          photos {
            id
            title
            description
            fluid(maxHeight: 900, maxWidth: 1600, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `).slider.nodes[0]

  const next = useCallback(() => {
    if (animating) return
    const nextIndex =
      activeIndex === data.photos.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }, [animating, activeIndex, setActiveIndex, data.photos.length])

  const previous = useCallback(() => {
    if (animating) return
    const nextIndex =
      activeIndex === 0 ? data.photos.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }, [animating, activeIndex, setActiveIndex, data.photos.length])

  const goToIndex = useCallback(
    newIndex => {
      if (animating) return
      setActiveIndex(newIndex)
    },
    [animating, setActiveIndex]
  )

  return (
    <StyledCarousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      slide
    >
      <CarouselIndicators
        items={data.photos}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {data.photos.map((photo, index) => (
        <CarouselItem
          onExiting={setAnimatingTrue}
          onExited={setAnimatingFalse}
          key={photo.id}
          slide
        >
          <StyledImg
            alt={photo.title}
            fluid={photo.fluid}
            loading={index === 0 ? 'eager' : 'lazy'}
          />
          <CarouselCaption captionText="" captionHeader={photo.description} />
        </CarouselItem>
      ))}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </StyledCarousel>
  )
}

const StyledCarousel = styled(Carousel)`
  background-color: black;
`

const StyledImg = styled(Img)`
  max-height: 92vh;

  @media only screen and (min-width: 1600px) {
    margin: auto;
    max-width: 1760px;
  }
`
