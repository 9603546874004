import React from 'react'
import {
  AboutSection,
  ContactSection,
  EnrollmentSection,
  EventsSection,
  GroupSection,
  SliderSection,
} from './partial'

export const MainContent: React.FC = () => (
  <>
    <SliderSection />
    <AboutSection />
    <GroupSection />
    <EnrollmentSection />
    <EventsSection />
    <ContactSection />
  </>
)
